.sidebar {
  width: 20%;
  height: 100vh;
  border-right: 1px solid #dadada;
  position: fixed;
}
.login-block{
  width: 20%;
}
table > tr > td{
  padding: 16px;
}

.btn-primary {
    color: #0d6efd !important;
}
.btn-primary:hover{
  color: white !important;
}
table > tr > th{
  padding: 16px;
}
table > tr{
  border-bottom: 1px solid rgba(35, 35, 35, 0.123);
}
@media(max-width: 1024px){
  .login-block {
      width: 40%;
  }
}
@media(max-width: 414px) {
  .login-block {
      width: 60%;
  }
}